const STORAGE_KEY = "uiPreferences";

const getStoredPreferences = () => {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : null;
  } catch (error) {
    console.error("Error reading UI preferences:", error);
    return null;
  }
};

const state = {
  sectionStates: getStoredPreferences()?.sectionStates || {
    filters: false,
    legend: false,
    stats: false,
    occupancy: true, // Początkowe zwinięcie sekcji obłożenia
  },
};

const mutations = {
  SET_SECTION_STATE(state, { section, isCollapsed }) {
    state.sectionStates[section] = isCollapsed;
    // Persist to localStorage
    try {
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          sectionStates: state.sectionStates,
        })
      );
    } catch (error) {
      console.error("Error saving UI preferences:", error);
    }
  },
};

const actions = {
  toggleSection({ commit, state }, section) {
    commit("SET_SECTION_STATE", {
      section,
      isCollapsed: !state.sectionStates[section],
    });
  },
};

const getters = {
  isSectionCollapsed: (state) => (section) => state.sectionStates[section],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
