import { createStore } from "vuex";
import employees from "./modules/employees";
import cloudMessage from "./modules/cloudMessage";
import app from "./modules/app";
import modal from "./modules/modal";
import calendar from "./modules/calendar";
import venueSettings from "./modules/venueSettings";
import uiPreferences from "./modules/uiPreferences";

const store = createStore({
  modules: {
    employees,
    cloudMessage,
    app,
    modal,
    calendar,
    venueSettings,
    uiPreferences,
  },
});

export default store;
