<script setup>
import { useStore } from "vuex";
import { watch, onMounted } from "vue";
import Intercom from "@intercom/messenger-js-sdk";

const store = useStore();

/**
 * Inicjalizuje Intercom z danymi użytkownika
 */
const initializeIntercom = () => {
  const user = store.state.employees.user;

  // Sprawdź czy użytkownik jest zalogowany
  if (!user) {
    return;
  }

  const venueName =
    user._employee_venue_profiles?.find(
      (v) => v.venue_id === user.current_venue_id
    )?.name || "Unknown Venue";

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: user.id,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    created_at: Math.floor(user.created_at / 1000),
    company: {
      company_id: user.current_venue_id?.toString() || "0",
      name: venueName,
    },
    // Custom attributes z prostymi nazwami
    venue_id: user.current_venue_id,
    venue_name: venueName,
    user_role: user.app_role_details?.name,
    last_login: Math.floor(user.last_login / 1000),
    is_active: user.is_active,
  });
};

// Obserwuj zmiany stanu użytkownika
watch(
  () => store.state.employees.user,
  (newUser) => {
    if (newUser && import.meta.env.PROD) {
      initializeIntercom();
    }
  }
);

// Inicjalizuj Intercom po zamontowaniu komponentu, jeśli użytkownik jest zalogowany
onMounted(() => {
  if (store.state.employees.user && import.meta.env.PROD) {
    initializeIntercom();
  }
});
</script>

<template>
  <!-- Intercom messenger will be injected into the DOM automatically -->
</template>

<style>
/* Ustawienie niższego z-indexu dla wszystkich komponentów Intercom */
#intercom-container,
.intercom-launcher-frame,
.intercom-messenger-frame,
.intercom-lightweight-app {
  z-index: 39 !important;
}
</style>
