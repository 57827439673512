/**
 * Narzędzia do zarządzania Intercomem
 */

/**
 * Zamyka i resetuje instancję Intercomu.
 * Powinno być wywołane podczas wylogowania użytkownika.
 */
export const shutdownIntercom = () => {
  // Sprawdź, czy Intercom jest dostępny w window
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
};
