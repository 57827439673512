import { api } from "@/api/api";
import { employeeApi } from "@/api/modules/employee";
import router from "@/router";
import calendarCache from "@/utils/calendarCache";
import { shutdownIntercom } from "@/utils/intercomUtils";
import logger from "../../../logger";

export default {
  async login({ commit, dispatch, state }, credentials) {
    logger.log("Action login with credentials:", credentials);
    try {
      const response = await api.post(`auth/login`, credentials);
      const { authToken } = response.data;
      if (authToken) {
        logger.info("Received authToken:", authToken);
        commit("SET_AUTH_TOKEN", authToken);
        const userDetails = await dispatch("fetchUserDetails");

        // Only proceed to venue selection if not in password reset mode
        if (
          !state.hasMagicToken &&
          userDetails._employee_venue_profiles?.length === 1
        ) {
          const venue = userDetails._employee_venue_profiles[0];
          await dispatch("setEmployeeVenue", venue.venue_id);
        }
      } else {
        throw new Error("Authentication failed: No token received");
      }
      return response;
    } catch (error) {
      logger.error("Login failed:", error);

      if (error.response?.data?.code === "ERROR_CODE_ACCESS_DENIED") {
        dispatch(
          "cloudMessage/showMessage",
          {
            message: "Nieprawidłowy email lub hasło",
            type: "error",
            duration: 5000,
          },
          { root: true }
        );
      } else {
        dispatch(
          "cloudMessage/showMessage",
          {
            message: "Wystąpił błąd podczas logowania. Spróbuj ponownie.",
            type: "error",
            duration: 5000,
          },
          { root: true }
        );
      }

      throw error;
    }
  },

  logout({ commit }) {
    logger.log("Action logout");
    // Zamknij sesję Intercomu przed wyczyszczeniem danych użytkownika
    shutdownIntercom();
    calendarCache.clear();
    commit("SET_USER", null);
    commit("SET_AUTH_TOKEN", null);
    commit("SET_AVAILABLE_VENUES", []);
    commit("SET_CURRENT_VENUE", { venueId: null, appRoleId: null });
    commit("SET_MAGIC_TOKEN", false);
    commit("SET_MESSAGE", "You have been logged out.");
    router.push("/login");
  },

  async requestPasswordReset({ dispatch }, email) {
    logger.log("Action requestPasswordReset with email:", email);
    try {
      await api.get(`auth/magic-link`, {
        params: { email },
      });
      dispatch(
        "cloudMessage/showMessage",
        {
          message:
            "Jeśli podany email istnieje w systemie, link do resetowania hasła został wysłany.",
          type: "success",
          duration: 5000,
        },
        { root: true }
      );
    } catch (error) {
      logger.error("Password reset request failed:", error);
      dispatch(
        "cloudMessage/showMessage",
        {
          message:
            "Jeśli podany email istnieje w systemie, link do resetowania hasła został wysłany.",
          type: "success",
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async checkMagicToken({ commit, dispatch }, token) {
    logger.log("Action checkMagicToken with token:", token);
    try {
      const response = await api.post(`auth/magic_login`, {
        magic_token: token,
      });
      const authToken = response.data;
      if (authToken) {
        logger.info("Magic token verified, token:", authToken);
        commit("SET_AUTH_TOKEN", authToken);
        commit("SET_MAGIC_TOKEN", true);
        // Fetch user details but don't redirect to venue selection
        await dispatch("fetchUserDetails");
        dispatch(
          "cloudMessage/showMessage",
          {
            message:
              "Token zweryfikowany pomyślnie. Możesz teraz ustawić nowe hasło.",
            type: "success",
            duration: 5000,
          },
          { root: true }
        );
      } else {
        throw new Error("Invalid magic token");
      }
    } catch (error) {
      logger.error("Magic token verification failed:", error);
      // Clear token and redirect to login
      commit("SET_AUTH_TOKEN", null);
      commit("SET_MAGIC_TOKEN", false);
      router.push("/login");
      dispatch(
        "cloudMessage/showMessage",
        {
          message: "Nieprawidłowy lub wygasły token. Zaloguj się ponownie.",
          type: "error",
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async setNewPassword({ commit, dispatch, state }, { newPassword }) {
    logger.log("Action setNewPassword with newPassword.");
    try {
      const employeeId = state.user.id;
      await employeeApi.updatePassword(employeeId, newPassword);
      logger.info("New password has been set");

      // Reset magic token state after successful password change
      commit("SET_MAGIC_TOKEN", false);

      const userDetails = await dispatch("fetchUserDetails");
      // After password change, handle venue selection
      if (userDetails._employee_venue_profiles?.length === 1) {
        const venue = userDetails._employee_venue_profiles[0];
        await dispatch("setEmployeeVenue", venue.venue_id);
        router.push("/");
      }

      dispatch(
        "cloudMessage/showMessage",
        {
          message: "Hasło zostało zmienione pomyślnie.",
          type: "success",
          duration: 5000,
        },
        { root: true }
      );
    } catch (error) {
      logger.error("Setting new password failed:", error);
      dispatch(
        "cloudMessage/showMessage",
        {
          message: "Błąd podczas zmiany hasła.",
          type: "error",
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async handleAuthError({ dispatch }) {
    logger.log("Handling auth error - token expired or invalid");
    calendarCache.clear();
    await dispatch("logout");
    router.push("/login");
    dispatch(
      "cloudMessage/showMessage",
      {
        message: "Twoja sesja wygasła. Zaloguj się ponownie.",
        type: "warning",
        duration: 5000,
      },
      { root: true }
    );
  },
};
