import calendarCache from "@/utils/calendarCache";

const isSameDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
};

export default {
  // Statistics getters
  todayReservationsCount: (state) => {
    const selectedDate = state.ui.selectedDate;
    return state.core.reservations.filter(
      (res) =>
        isSameDay(new Date(res.start_time), selectedDate) && !res.is_group_class
    ).length;
  },

  unpaidReservationsCount: (state) => {
    const selectedDate = state.ui.selectedDate;
    return state.core.reservations.filter(
      (res) =>
        isSameDay(new Date(res.start_time), selectedDate) &&
        !res.is_group_class &&
        res.payment_status !== "paid"
    ).length;
  },

  // Usunięto occupancyRate - zastąpione przez TagOccupancyRates.vue

  todayRevenue: (state) => {
    const selectedDate = state.ui.selectedDate;
    const regularRevenue = state.core.reservations
      .filter(
        (res) =>
          isSameDay(new Date(res.start_time), selectedDate) &&
          !res.is_group_class &&
          res.payment_status === "paid"
      )
      .reduce((sum, res) => sum + (res.price || 0), 0);

    const multisportRevenue = state.core.reservations
      .filter(
        (res) =>
          isSameDay(new Date(res.start_time), selectedDate) &&
          !res.is_group_class
      )
      .reduce((sum, res) => sum + (res.discount_cards || 0) * 15, 0);

    return regularRevenue + multisportRevenue;
  },

  multisportRevenue: (state) => {
    const selectedDate = state.ui.selectedDate;
    return state.core.reservations
      .filter(
        (res) =>
          isSameDay(new Date(res.start_time), selectedDate) &&
          !res.is_group_class
      )
      .reduce((sum, res) => sum + (res.discount_cards || 0) * 15, 0);
  },

  // Partner cards getters
  totalDiscountCards: (state) => {
    const selectedDate = state.ui.selectedDate;
    return state.core.reservations
      .filter(
        (res) =>
          isSameDay(new Date(res.start_time), selectedDate) &&
          !res.is_group_class
      )
      .reduce((sum, res) => sum + (res.discount_cards || 0), 0);
  },

  scannedDiscountCards: (state) => {
    const selectedDate = state.ui.selectedDate;
    return state.core.reservations
      .filter(
        (res) =>
          isSameDay(new Date(res.start_time), selectedDate) &&
          !res.is_group_class
      )
      .reduce((sum, res) => sum + (res.discount_cards_scanned || 0), 0);
  },

  isLoading: (state) => state.ui.isLoading,

  hasError: (state) =>
    Object.values(state.error).some((error) => error !== null),

  getError: (state) => (key) => state.error[key],

  filteredSportObjects: (state) => {
    if (!state.ui.selectedTag) return state.core.sportObjects;
    return state.core.sportObjects.filter(
      (obj) => obj.tag_id === state.ui.selectedTag.id
    );
  },

  getPriceLists: (state) => (key) => state.core.priceLists[key] || [],

  isCacheValid: () => (key) => calendarCache.isValid(key),

  getCachedData: () => (key) => calendarCache.get(key),

  isSidebarVisible: (state) => state.ui.isSidebarVisible,
};
